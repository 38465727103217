const keysSum = (keys, itemArray) =>
  keys.reduce((acc, curr) => {
    return acc + parseInt(itemArray[curr]);
  }, 0);

const getPBAResults = (items) => {
  return Object.values(items).reduce(
    (acc, curr) => {
      if (curr === '1') {
        let PBA_A = acc.PBA_A + 1;
        return { ...acc, PBA_A };
      }
      if (curr === '2') {
        let PBA_B = acc.PBA_B + 1;
        return { ...acc, PBA_B };
      }
      if (curr === '3') {
        let PBA_C = acc.PBA_C + 1;
        return { ...acc, PBA_C };
      }
      return { ...acc };
    },
    { PBA_A: 0, PBA_B: 0, PBA_C: 0 }
  );
};

export const getDASSResults = (items) => {
  // console.log({ dass: items });
  const depressionKeys = [
    'DASS_03',
    'DASS_05',
    'DASS_10',
    'DASS_13',
    'DASS_16',
    'DASS_17',
    'DASS_21'
  ];
  const anxietyKeys = [
    'DASS_02',
    'DASS_04',
    'DASS_07',
    'DASS_09',
    'DASS_15',
    'DASS_19',
    'DASS_20'
  ];
  const stressKeys = [
    'DASS_01',
    'DASS_06',
    'DASS_08',
    'DASS_11',
    'DASS_12',
    'DASS_14',
    'DASS_18'
  ];

  // const DASS_DEP_SUM = keysSum(depressionKeys, items);
  // const DASS_ANX_SUM = keysSum(anxietyKeys, items);
  // const DASS_STR_SUM = keysSum(stressKeys, items);
  // console.log({ DASS_DEP_SUM, DASS_ANX_SUM, DASS_STR_SUM });
  // const DASS_DEP = keysSum(depressionKeys, items) / depressionKeys.length;
  // const DASS_ANX = keysSum(anxietyKeys, items) / anxietyKeys.length;
  // const DASS_STR = keysSum(stressKeys, items) / stressKeys.length;
  const DASS_DEP = keysSum(depressionKeys, items);
  const DASS_ANX = keysSum(anxietyKeys, items);
  const DASS_STR = keysSum(stressKeys, items);
  return { DASS_DEP, DASS_ANX, DASS_STR };
};

export const getScreeningParentResults = (data) => {
  // const dumm = {
  //   DASS_09: '2',
  //   DASS_08: '3',
  //   DASS_19: '1',
  //   DASS_07: '2',
  //   DASS_18: '2',
  //   DASS_06: '2',
  //   DASS_17: '2',
  //   DASS_01: '0',
  //   DASS_12: '2',
  //   DASS_11: '2',
  //   DASS_10: '3',
  //   DASS_21: '1',
  //   DASS_20: '1',
  //   DASS_05: '3',
  //   DASS_16: '3',
  //   DASS_04: '3',
  //   DASS_15: '2',
  //   DASS_03: '2',
  //   DASS_14: '1',
  //   DASS_02: '1',
  //   DASS_13: '1',
  //   PBA_04: '2',
  //   PBA_05: '3',
  //   PBA_01: '2',
  //   PBA_02: '3',
  //   PBA_03: '1'
  // };

  const dataKeys = Object.keys(data);
  const dassKeys = dataKeys.filter((k) => k.includes('DASS_'));
  const pbaKeys = dataKeys.filter((k) => k.includes('PBA_'));
  const PBA_ITEMS = pbaKeys.reduce((acc, curr) => {
    return { ...acc, [curr]: data[curr] };
  }, {});
  const DASS_ITEMS = dassKeys.reduce((acc, curr) => {
    return { ...acc, [curr]: data[curr] };
  }, {});
  const PBA_RESULTS = getPBAResults(PBA_ITEMS);
  const DASS_RESULTS = getDASSResults(DASS_ITEMS);

  // console.log({ PBA_RESULTS, DASS_RESULTS });
  // console.log('on parseScreeningParentData');
  // console.log({ data });
  return { ...PBA_RESULTS, ...DASS_RESULTS };
};

// {
//   "DASS_01": "1",
//   "DASS_02": "1",
//   "DASS_03": "2",
//   "DASS_04": "0",
//   "DASS_05": "3",
//   "DASS_06": "2",
//   "DASS_07": "1",
//   "DASS_08": "0",
//   "DASS_09": "1",
//   "DASS_10": "2",
//   "DASS_11": "0",
//   "DASS_12": "1",
//   "DASS_13": "2",
//   "DASS_14": "1",
//   "DASS_15": "0",
//   "DASS_16": "2",
//   "DASS_17": "3",
//   "DASS_18": "2",
//   "DASS_19": "2",
//   "DASS_20": "1",
//   "DASS_21": "0"
// }

//  {
//   DASS_ANX: 0.8571428571428571,
//   DASS_DEP: 2,
//   DASS_STR: 1,
//  }

export const getScreeningChildResults = (data) => {
  const demoItems = getChildDemoItems(data);
  const sqdResults = getSDQResults(data);
  const kindlResults = getKINDLResults(data);
  // console.log({ demoItems, sqdResults, kindlResults });
  return { ...demoItems, ...sqdResults, ...kindlResults };
};

const getChildDemoItems = (data) => {
  const itemsToKeep = [
    'CHILD_NAME',
    'CHILD_LASTNAME',
    'CHILD_BIRTH',
    'CHILD_AGE',
    'CHILD_SEX',
    'CHILD_PARENT',
    'CHILD_PARENT_OTHER',
    'CHILD_SCHOOL',
    'CHILD_GRADE'
  ];
  const items = itemsToKeep.reduce((acc, curr) => {
    return { ...acc, [curr]: data[curr] };
  }, {});
  return items;
};

export const getKINDLResults = (data) => {
  const { KINDL_VER } = data;
  const kindlItems = KINDLMapper(data);
  const PHYWB_SUM = keysSum(
    ['PHYWB_01', 'PHYWB_02', 'PHYWB_03', 'PHYWB_04'],
    kindlItems
  );
  const EMOWB_SUM = keysSum(
    ['EMOWB_01', 'EMOWB_02', 'EMOWB_03', 'EMOWB_04'],
    kindlItems
  );
  const SELFE_SUM = keysSum(
    ['SELFE_01', 'SELFE_02', 'SELFE_03', 'SELFE_04'],
    kindlItems
  );
  const FAMIL_SUM = keysSum(
    ['FAMIL_01', 'FAMIL_02', 'FAMIL_03', 'FAMIL_04'],
    kindlItems
  );
  const FRIEN_SUM = keysSum(
    ['FRIEN_01', 'FRIEN_02', 'FRIEN_03', 'FRIEN_04'],
    kindlItems
  );
  const SCHOOL_SUM = keysSum(
    ['SCHOOL_01', 'SCHOOL_02', 'SCHOOL_03', 'SCHOOL_04'],
    kindlItems
  );
  const KIDDY_SUM = keysSum(
    [
      'KIDDY_25',
      'KIDDY_26',
      'KIDDY_27',
      'KIDDY_28',
      'KIDDY_29',
      'KIDDY_30',
      'KIDDY_31',
      'KIDDY_32',
      'KIDDY_33',
      'KIDDY_34',
      'KIDDY_35',
      'KIDDY_36',
      'KIDDY_37',
      'KIDDY_38',
      'KIDDY_39',
      'KIDDY_40',
      'KIDDY_41',
      'KIDDY_42',
      'KIDDY_43',
      'KIDDY_44',
      'KIDDY_45',
      'KIDDY_46'
    ],
    kindlItems
  );
  const KINDL_SUM =
    PHYWB_SUM + EMOWB_SUM + SELFE_SUM + FAMIL_SUM + FRIEN_SUM + SCHOOL_SUM;

  const results = {
    PHYWB_SUM,
    EMOWB_SUM,
    SELFE_SUM,
    FAMIL_SUM,
    FRIEN_SUM,
    SCHOOL_SUM,
    KINDL_SUM,
    KIDDY_SUM,
    PHYWB_AVE: PHYWB_SUM / 4,
    EMOWB_AVE: EMOWB_SUM / 4,
    SELFE_AVE: SELFE_SUM / 4,
    FAMIL_AVE: FAMIL_SUM / 4,
    FRIEN_AVE: FRIEN_SUM / 4,
    SCHOOL_AVE: SCHOOL_SUM / 4,
    KINDL_AVE: KINDL_SUM / 24,
    KIDDY_AVE: KIDDY_SUM / 22,
    ...kindlItems
  };
  if (KINDL_VER === 'P7_17') {
    delete results.KIDDY_SUM;
    delete results.KIDDY_AVE;
  }
  return results;
};
const KINDLMapper = (data) => {
  const { KINDL_VER } = data;
  // console.log({ KINDL_VER });
  let kindlMap = {};
  if (KINDL_VER === 'P3_6') {
    kindlMap = {
      PHYWB_01: Math.abs(parseInt(data['KINDLp3-6_05-01']) - 6), // reversed
      PHYWB_02: Math.abs(parseInt(data['KINDLp3-6_05-02']) - 6), // reversed
      PHYWB_03: Math.abs(parseInt(data['KINDLp3-6_05-03']) - 6), // reversed
      PHYWB_04: parseInt(data['KINDLp3-6_05-04']),
      EMOWB_01: parseInt(data['KINDLp3-6_06-01']),
      EMOWB_02: Math.abs(parseInt(data['KINDLp3-6_06-02']) - 6), // reversed
      EMOWB_03: Math.abs(parseInt(data['KINDLp3-6_06-03']) - 6), // reversed
      EMOWB_04: Math.abs(parseInt(data['KINDLp3-6_06-04']) - 6), // reversed
      SELFE_01: parseInt(data['KINDLp3-6_07-01']),
      SELFE_02: parseInt(data['KINDLp3-6_07-02']),
      SELFE_03: parseInt(data['KINDLp3-6_07-03']),
      SELFE_04: parseInt(data['KINDLp3-6_07-04']),
      FAMIL_01: parseInt(data['KINDLp3-6_08-01']),
      FAMIL_02: parseInt(data['KINDLp3-6_08-02']),
      FAMIL_03: Math.abs(parseInt(data['KINDLp3-6_08-03']) - 6), // reversed
      FAMIL_04: Math.abs(parseInt(data['KINDLp3-6_08-04']) - 6), // reversed
      FRIEN_01: parseInt(data['KINDLp3-6_09-01']),
      FRIEN_02: parseInt(data['KINDLp3-6_09-02']),
      FRIEN_03: parseInt(data['KINDLp3-6_09-03']),
      FRIEN_04: Math.abs(parseInt(data['KINDLp3-6_09-04']) - 6), // reversed
      SCHOOL_01: parseInt(data['KINDLp3-6_10-01']),
      SCHOOL_02: parseInt(data['KINDLp3-6_10-02']),
      SCHOOL_03: parseInt(data['KINDLp3-6_10-03']),
      SCHOOL_04: Math.abs(parseInt(data['KINDLp3-6_10-04']) - 6), // reversed
      KIDDY_25: Math.abs(parseInt(data['KINDLp3-6_11-01']) - 6),
      KIDDY_26: parseInt(data['KINDLp3-6_11-02']),
      KIDDY_27: parseInt(data['KINDLp3-6_11-03']),
      KIDDY_28: Math.abs(parseInt(data['KINDLp3-6_11-04']) - 6),
      KIDDY_29: parseInt(data['KINDLp3-6_11-05']),
      KIDDY_30: parseInt(data['KINDLp3-6_11-06']),
      KIDDY_31: Math.abs(parseInt(data['KINDLp3-6_11-07']) - 6),
      KIDDY_32: parseInt(data['KINDLp3-6_11-08']),
      KIDDY_33: parseInt(data['KINDLp3-6_11-09']),
      KIDDY_34: Math.abs(parseInt(data['KINDLp3-6_11-10']) - 6),
      KIDDY_35: parseInt(data['KINDLp3-6_11-11']),
      KIDDY_36: Math.abs(parseInt(data['KINDLp3-6_11-12']) - 6),
      KIDDY_37: parseInt(data['KINDLp3-6_11-13']),
      KIDDY_38: Math.abs(parseInt(data['KINDLp3-6_11-14']) - 6),
      KIDDY_39: Math.abs(parseInt(data['KINDLp3-6_11-15']) - 6),
      KIDDY_40: parseInt(data['KINDLp3-6_11-16']),
      KIDDY_41: Math.abs(parseInt(data['KINDLp3-6_11-17']) - 6),
      KIDDY_42: parseInt(data['KINDLp3-6_11-18']),
      KIDDY_43: parseInt(data['KINDLp3-6_11-19']),
      KIDDY_44: Math.abs(parseInt(data['KINDLp3-6_11-20']) - 6),
      KIDDY_45: Math.abs(parseInt(data['KINDLp3-6_11-21']) - 6), // invertido, usando traducción del inglés original
      KIDDY_46: Math.abs(parseInt(data['KINDLp3-6_11-22']) - 6)
    };
  }
  if (KINDL_VER === 'P7_17') {
    kindlMap = {
      PHYWB_01: Math.abs(parseInt(data['KINDLp7-17_05-01']) - 6), // reversed
      PHYWB_02: Math.abs(parseInt(data['KINDLp7-17_05-02']) - 6), // reversed
      PHYWB_03: Math.abs(parseInt(data['KINDLp7-17_05-03']) - 6), // reversed
      PHYWB_04: parseInt(data['KINDLp7-17_05-04']),
      EMOWB_01: parseInt(data['KINDLp7-17_06-01']),
      EMOWB_02: Math.abs(parseInt(data['KINDLp7-17_06-02']) - 6), // reversed
      EMOWB_03: Math.abs(parseInt(data['KINDLp7-17_06-03']) - 6), // reversed
      EMOWB_04: Math.abs(parseInt(data['KINDLp7-17_06-04']) - 6), // reversed
      SELFE_01: parseInt(data['KINDLp7-17_07-01']),
      SELFE_02: parseInt(data['KINDLp7-17_07-02']),
      SELFE_03: parseInt(data['KINDLp7-17_07-03']),
      SELFE_04: parseInt(data['KINDLp7-17_07-04']),
      FAMIL_01: parseInt(data['KINDLp7-17_08-01']),
      FAMIL_02: parseInt(data['KINDLp7-17_08-02']),
      FAMIL_03: Math.abs(parseInt(data['KINDLp7-17_08-03']) - 6), // reversed
      FAMIL_04: Math.abs(parseInt(data['KINDLp7-17_08-04']) - 6), // reversed
      FRIEN_01: parseInt(data['KINDLp7-17_09-01']),
      FRIEN_02: parseInt(data['KINDLp7-17_09-02']),
      FRIEN_03: parseInt(data['KINDLp7-17_09-03']),
      FRIEN_04: Math.abs(parseInt(data['KINDLp7-17_09-04']) - 6), // reversed
      SCHOOL_01: parseInt(data['KINDLp7-17_10-01']),
      SCHOOL_02: parseInt(data['KINDLp7-17_10-02']),
      SCHOOL_03: Math.abs(parseInt(data['KINDLp7-17_10-03']) - 6), // reversed *NOt equal as other SCALE*
      SCHOOL_04: Math.abs(parseInt(data['KINDLp7-17_10-04']) - 6) // reversed
    };
  }
  return kindlMap;
};

const getSDQResults = (data) => {
  const sdqItems = SDQMapper(data);
  const EMO_SUM = keysSum(
    ['EMO_01', 'EMO_02', 'EMO_03', 'EMO_04', 'EMO_05'],
    sdqItems
  );
  const CONDUCT_SUM = keysSum(
    ['CONDUCT_01', 'CONDUCT_02', 'CONDUCT_03', 'CONDUCT_04', 'CONDUCT_05'],
    sdqItems
  );
  const HIPER_SUM = keysSum(
    ['HIPER_01', 'HIPER_02', 'HIPER_03', 'HIPER_04', 'HIPER_05'],
    sdqItems
  );
  const COMP_SUM = keysSum(
    ['COMP_01', 'COMP_02', 'COMP_03', 'COMP_04', 'COMP_05'],
    sdqItems
  );
  const PROS_SUM = keysSum(
    ['PROS_01', 'PROS_02', 'PROS_03', 'PROS_04', 'PROS_05'],
    sdqItems
  );
  const SDQ_SUM = EMO_SUM + CONDUCT_SUM + HIPER_SUM + COMP_SUM;
  return {
    SDQ_SUM,
    EMO_SUM,
    CONDUCT_SUM,
    HIPER_SUM,
    COMP_SUM,
    PROS_SUM,
    ...sdqItems
  };
};

const SDQMapper = (data) => {
  const { SDQ_VER } = data;
  // console.log({ SDQ_VER });
  const commonItems = {
    SDQ_29: data['SDQ_29'],
    SDQ_30: parseInt(data['SDQ_30']),
    SDQ_31: parseInt(data['SDQ_31']),
    SDQ_32: parseInt(data['SDQ_32']),
    SDQ_33_1: parseInt(data['SDQ_33_1']),
    SDQ_33_2: parseInt(data['SDQ_33_2']),
    SDQ_33_3: parseInt(data['SDQ_33_3']),
    SDQ_33_4: parseInt(data['SDQ_33_4']),
    SDQ_34: parseInt(data['SDQ_34']),
    SDQ_VER: data['SDQ_VER']
  };
  let sdqMap = {};
  if (SDQ_VER === 'P2_4') {
    sdqMap = {
      EMO_01: parseInt(data['SDQp2-4_06']),
      EMO_02: parseInt(data['SDQp2-4_11']),
      EMO_03: parseInt(data['SDQp2-4_16']),
      EMO_04: parseInt(data['SDQp2-4_19']),
      EMO_05: parseInt(data['SDQp2-4_27']),
      CONDUCT_01: parseInt(data['SDQp2-4_08']),
      CONDUCT_02: Math.abs(parseInt(data['SDQp2-4_10']) - 2), // reversed
      CONDUCT_03: parseInt(data['SDQp2-4_15']),
      CONDUCT_04: parseInt(data['SDQp2-4_21']),
      CONDUCT_05: parseInt(data['SDQp2-4_25']),
      HIPER_01: parseInt(data['SDQp2-4_05']),
      HIPER_02: parseInt(data['SDQp2-4_13']),
      HIPER_03: parseInt(data['SDQp2-4_18']),
      HIPER_04: Math.abs(parseInt(data['SDQp2-4_24']) - 2), // reversed
      HIPER_05: Math.abs(parseInt(data['SDQp2-4_28']) - 2), // reversed
      COMP_01: parseInt(data['SDQp2-4_09']),
      COMP_02: Math.abs(parseInt(data['SDQp2-4_14']) - 2), // reversed
      COMP_03: Math.abs(parseInt(data['SDQp2-4_17']) - 2), // reversed
      COMP_04: parseInt(data['SDQp2-4_22']),
      COMP_05: parseInt(data['SDQp2-4_26']),
      PROS_01: parseInt(data['SDQp2-4_04']),
      PROS_02: parseInt(data['SDQp2-4_07']),
      PROS_03: parseInt(data['SDQp2-4_12']),
      PROS_04: parseInt(data['SDQp2-4_20']),
      PROS_05: parseInt(data['SDQp2-4_23']),
      ...commonItems
    };
  }
  if (SDQ_VER === 'P5_17') {
    sdqMap = {
      EMO_01: parseInt(data['SDQp4-17_06']),
      EMO_02: parseInt(data['SDQp4-17_11']),
      EMO_03: parseInt(data['SDQp4-17_16']),
      EMO_04: parseInt(data['SDQp4-17_19']),
      EMO_05: parseInt(data['SDQp4-17_27']),
      CONDUCT_01: parseInt(data['SDQp4-17_08']),
      CONDUCT_02: Math.abs(parseInt(data['SDQp4-17_10']) - 2), // reversed
      CONDUCT_03: parseInt(data['SDQp4-17_15']),
      CONDUCT_04: parseInt(data['SDQp4-17_21']),
      CONDUCT_05: parseInt(data['SDQp4-17_25']),
      HIPER_01: parseInt(data['SDQp4-17_05']),
      HIPER_02: parseInt(data['SDQp4-17_13']),
      HIPER_03: parseInt(data['SDQp4-17_18']),
      HIPER_04: Math.abs(parseInt(data['SDQp4-17_24']) - 2), // reversed
      HIPER_05: Math.abs(parseInt(data['SDQp4-17_28']) - 2), // reversed
      COMP_01: parseInt(data['SDQp4-17_09']),
      COMP_02: Math.abs(parseInt(data['SDQp4-17_14']) - 2), // reversed
      COMP_03: Math.abs(parseInt(data['SDQp4-17_17']) - 2), // reversed
      COMP_04: parseInt(data['SDQp4-17_22']),
      COMP_05: parseInt(data['SDQp4-17_26']),
      PROS_01: parseInt(data['SDQp4-17_04']),
      PROS_02: parseInt(data['SDQp4-17_07']),
      PROS_03: parseInt(data['SDQp4-17_12']),
      PROS_04: parseInt(data['SDQp4-17_20']),
      PROS_05: parseInt(data['SDQp4-17_23']),
      ...commonItems
    };
  }
  return sdqMap;
};
