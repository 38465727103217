/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      rut
      name
      lastName
      email
      emailStatus
      phone
      responses {
        items {
          id
          sku
          date
          userID
          finished
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      status
      globalStatus
      caseStatus
      comments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      authComments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      info
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      rut
      name
      lastName
      email
      emailStatus
      phone
      responses {
        items {
          id
          sku
          date
          userID
          finished
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      status
      globalStatus
      caseStatus
      comments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      authComments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      info
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      rut
      name
      lastName
      email
      emailStatus
      phone
      responses {
        items {
          id
          sku
          date
          userID
          finished
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      status
      globalStatus
      caseStatus
      comments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      authComments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      info
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserComment = /* GraphQL */ `
  mutation CreateUserComment(
    $input: CreateUserCommentInput!
    $condition: ModelUserCommentConditionInput
  ) {
    createUserComment(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      authID
      auth {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      comment
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserComment = /* GraphQL */ `
  mutation UpdateUserComment(
    $input: UpdateUserCommentInput!
    $condition: ModelUserCommentConditionInput
  ) {
    updateUserComment(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      authID
      auth {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      comment
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserComment = /* GraphQL */ `
  mutation DeleteUserComment(
    $input: DeleteUserCommentInput!
    $condition: ModelUserCommentConditionInput
  ) {
    deleteUserComment(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      authID
      auth {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      comment
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      sku
      date
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      finished
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      sku
      date
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      finished
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      sku
      date
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      finished
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
