/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      rut
      name
      lastName
      email
      emailStatus
      phone
      responses {
        items {
          id
          sku
          date
          userID
          finished
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      status
      globalStatus
      caseStatus
      comments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      authComments {
        items {
          id
          userID
          authID
          comment
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      info
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserComment = /* GraphQL */ `
  query GetUserComment($id: ID!) {
    getUserComment(id: $id) {
      id
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      authID
      auth {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      comment
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserComments = /* GraphQL */ `
  query ListUserComments(
    $filter: ModelUserCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          rut
          name
          lastName
          email
          emailStatus
          phone
          status
          globalStatus
          caseStatus
          info
          createdAt
          updatedAt
          owner
        }
        authID
        auth {
          id
          rut
          name
          lastName
          email
          emailStatus
          phone
          status
          globalStatus
          caseStatus
          info
          createdAt
          updatedAt
          owner
        }
        comment
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      sku
      date
      userID
      user {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      finished
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sku
        date
        userID
        user {
          id
          rut
          name
          lastName
          email
          emailStatus
          phone
          status
          globalStatus
          caseStatus
          info
          createdAt
          updatedAt
          owner
        }
        finished
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const usersByStatus = /* GraphQL */ `
  query UsersByStatus(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rut
        name
        lastName
        email
        emailStatus
        phone
        responses {
          nextToken
        }
        status
        globalStatus
        caseStatus
        comments {
          nextToken
        }
        authComments {
          nextToken
        }
        info
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const responsesBySku = /* GraphQL */ `
  query ResponsesBySku(
    $sku: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesBySKU(
      sku: $sku
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        date
        userID
        user {
          id
          rut
          name
          lastName
          email
          emailStatus
          phone
          status
          globalStatus
          caseStatus
          info
          createdAt
          updatedAt
          owner
        }
        finished
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const responsesByUser = /* GraphQL */ `
  query ResponsesByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responsesByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        date
        userID
        user {
          id
          rut
          name
          lastName
          email
          emailStatus
          phone
          status
          globalStatus
          caseStatus
          info
          createdAt
          updatedAt
          owner
        }
        finished
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
