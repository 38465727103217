import styled from 'styled-components';

const BaseSurveyWrapper = styled.div`
  .uai_header {
    position: absolute;
    right: 5%;
    top: 2%;
    img {
      max-width: 150px;
    }
  }
  .${({ theme }) => theme.baseName}_main {
    .progress {
      position: fixed;
      top: 0;
      width: 100% !important;
      border-radius: 0;
      height: 5px;
      left: 0;
      right: 0;
      z-index: 2;
      span {
        opacity: 0;
        width: 0;
      }
      .progress-bar {
        margin: 0px;
        background-color: ${({ theme }) => theme.colors.brandColor} !important;
      }
    }
    .panel-heading {
      padding: 1.5rem;
      border: none;
      background-color: ${({ theme }) => theme.colors.greyBackground};
      color: white;
      height: 200px;
      box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
      border-radius: 0;
      h3 {
        font-weight: 300;
      }
    }
    .${({ theme }) => theme.baseName}_container {
      ${'' /* margin-top: -100px; */}
      box-shadow: 0px 5px 15px -5px rgba(0,0,0,0.3);
    }
    @media (max-width: 574px) {
      .panel-body.card-block {
        h4 {
          text-align: center;
        }
      }
    }
  }
  .QuestionText {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.textColor};
    padding: 20px;
    line-height: 1.5em;
  }
  min-height: 100%;
  height: 100%;

  .${({ theme }) => theme.baseName}_main {
    height: 100%;
    ${'' /* min-height: 85vh; */}
    min-height: 100vh;
    padding-top: 3rem;
    overflow: scroll;
    @media (max-width: 574px) {
      padding-top: 1.5rem;
      padding-bottom: 3rem;
    }
  }
  .${({ theme }) => theme.baseName}_container, .panel-footer {
    ${'' /* max-width: 770px; */}
    max-width: 840px;
    padding: 2rem 3rem;
    margin: 0 auto;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
  }
  @media only screen and (max-width: 767px) {
    .${({ theme }) => theme.baseName}_container, .panel-footer {
      margin: 0 auto;
      width: 80%;
      padding: 1.5rem 0.85rem;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }
  }
  @media (min-width: 980px) {
    .${({ theme }) => theme.baseName}_container, .panel-footer {
      display: table;
      ${'' /* min-width: 840px; */}
    }
  }
  @media (max-width: 979px) {
    .${({ theme }) => theme.baseName}_container, .panel-footer {
      display: block;
      min-width: 0;
    }
  }
  @media (max-width: 564px) {
    .${({ theme }) => theme.baseName}_container, .panel-footer {
      width: 100%;
    }
  }

  .${({ theme }) => theme.baseName}_qcbc.form-inline {
    width: 100%;
    display: block;
    justify-content: space-around;
    text-align: center;
    .sv-q-column-2 {
      width: 40%;
      margin: 0 1.5%;
      display: inline-flex;
      .radio {
        width: 100%;
        border-radius: 4px;
        &.checked {
          border-radius: 4px;
          background: ${({ theme }) => theme.colors.brandColor};
          border-color: ${({ theme }) => theme.colors.brandSolid};
          color: #fff;
        }
        label {
          font-weight: 700;
          padding-top: 20px;
          padding-bottom: 20px;
          cursor: pointer;
          background: #f1f1f1;
          background: ${({ theme }) => theme.colors.itemBackground};
          border: none;
          transition: background 0.2s ease-in-out;
          padding: 20px;
          display: block;
          text-align: center;
          border-radius: 4px;
          input {
            display: none;
          }
          .circle {
            display: none;
          }
          &:hover {
            background: ${({ theme }) => theme.colors.itemBackgroundHover};
          }
        }
      }
    }

    @media (max-width: 767px) {
      .sv-q-column-2 {
        width: 100%;
        margin: 0;
      }
    }
  }

  .panel-footer.card-footer {
    width: 100%;
    border: none;
    margin-top: 25px;
    margin-bottom: 25px;
    background: transparent;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 574px) {
      margin-bottom: inherit;
    }

    input {
      width: 25%;
      background: ${({ theme }) => theme.colors.brandSolid};
      color: #fff;
      border: none;
      transition: all 0.2s;
      padding: 10px 25px;
      display: block;
      text-align: center;
      border-radius: 4px;
      font-weight: 600;
      &:hover {
        transform: translateY(-2px);
        background: ${({ theme }) => theme.colors.brandColor};
        box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
      }
    }
    @media (max-width: 574px) {
      input {
        width: 100%;
        background: ${({ theme }) => theme.colors.brandColor};
        padding: 8px 20px;
        font-weight: 700;
      }
    }
  }

  .${({ theme }) => theme.baseName}_qstn {
    h5 {
      min-height: 3.5rem;
      @media (max-width: 764px) {
        text-align: center;
      }
    }
    .small {
      margin-top: -1.5rem;
      margin-bottom: 1.5rem;
    }
    .${({ theme }) => theme.baseName}_qstn_title {
      font-size: 18px;
      color: ${({ theme }) => theme.colors.textColor};
      padding: 20px 0;
      line-height: 1.5em;
      font-weight: 500;
      min-height: 125px;
      @media (max-width: 764px) {
        min-height: inherit;
      }
    }
  }

  .${({ theme }) => theme.baseName}_qstn {
    .form-control {
      border: 2px solid #d2d2d6;
    }
  }

   {
    /** radio buttons Qualtrics style */
  }
  .${({ theme }) => theme.baseName}_qstn {
    .${({ theme }) => theme.baseName}_qcbc {
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      margin: 0;
      padding: 0;
      .radio.checked:focus {
        background-color: yellow;
      }
      .radio {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;
        border-radius: 4px;
        transition: all 0.15s ease-in;
        &.checked {
          border-radius: 4px;
          background: ${({ theme }) => theme.colors.brandColor};
          border-color: ${({ theme }) => theme.colors.brandSolid};
          color: #fff;
          &:hover {
            background: ${({ theme }) => theme.colors.brandColor};
          }
        }
        &.sv-q-col-1 {
          padding-right: 0;
          label {
            margin-bottom: 0;
          }
        }

        label {
          font-weight: 700;
          padding-top: 20px;
          padding-bottom: 20px;
          cursor: pointer;
          background: #f1f1f1;
          background: ${({ theme }) => theme.colors.itemBackground};
          border: none;
          transition: background 0.2s ease-in-out;
          padding: 20px;
          display: block;
          text-align: center;
          border-radius: 4px;
          input {
            display: none;
          }
          .circle {
            display: none;
          }
          &:hover {
            background: ${({ theme }) => theme.colors.itemBackgroundHover};
          }
        }
      }
    }
  }

   {
    /** checkbox buttons Qualtrics style */
  }
  .${({ theme }) => theme.baseName}_qstn {
    fieldset {
      .${({ theme }) => theme.baseName}_q_select_column.sv-q-column-2 {
        width: inherit;
        @media (max-width: 564px) {
          display: block;
          max-width: 100%;
          .checkbox {
            width: auto;
            margin: auto;
          }
        }
      }
      @media (max-width: 564px) {
        .checkbox {
          margin: auto;
        }
      }
    }
  }
  .${({ theme }) => theme.baseName}_qcbc {
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    padding: 0;
    .checkbox {
      width: 100%;
      margin: 10px 5px 10px 10px;
      ${'' /* margin-top: 5px; */}
      border-radius: 4px;
      transition: all 0.15s ease-in;
      &.checked {
        border-radius: 4px;
        background: ${({ theme }) => theme.colors.brandColor};
        border-color: ${({ theme }) => theme.colors.brandSolid};
        color: #fff;
        &:hover {
          background: ${({ theme }) => theme.colors.brandColor};
        }
      }

      label {
        font-weight: 700;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
        background: #f1f1f1;
        background: ${({ theme }) => theme.colors.itemBackground};
        border: none;
        transition: background 0.2s ease-in-out;
        padding: 20px;
        display: block;
        text-align: center;
        border-radius: 4px;
        input {
          display: none;
        }
        .circle {
          display: none;
        }
        &:hover {
          background: ${({ theme }) => theme.colors.itemBackgroundHover};
        }
      }
    }
  }

   {
    /**Panels */
  }

  .${({ theme }) => theme.baseName}_p_container {
    .${({ theme }) => theme.baseName}_row {
      display: flex;
      width: 100%;
    }
    ${'' /* @media (max-width: 564px) {
      .${({ theme }) => theme.baseName}_row {
        display: block;
      }
    } */}
  }

  .Footer {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 14px;
    max-width: 810px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    img {
      max-width: 250px;
    }
    .btg-logo {
      max-width: 125px;
    }
  }
  .PushStickyFooter {
    height: 50px;
  }
  .Plug {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    a {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.extraLight};
      text-decoration: none;
      padding: 12px 24px;
      background: rgba(61, 61, 68, 0.3);
      font-weight: 600;
    }
    @media (max-width: 574px) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      a {
        width: 100%;
      }
    }

    @media (min-width: 574px) {
      a {
        color: #fff;
        height: 40px;
        float: right;
        background: rgba(61, 61, 68, 0.3);
        transition: background 0.3s;
        text-align: center;
        border-radius: 5px 0 0 0;
      }
    }
  }

  .${({ theme }) => theme.baseName}_main {
    .${({ theme }) => theme.baseName}_q_matrix {
      thead {
        tr {
          th {
            ${'' /* font-size: 0.9rem; */}
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          transition: background 0.2s ease-in-out;
          &:hover {
            ${'' /* background: #f1f1f1; */}
            background: rgba(0, 0, 0, 0.06);
          }
          td {
            &:first-child {
              text-align: left;
              font-size: 0.9rem;
            }
            text-align: center;
            vertical-align: middle;
            min-width: inherit !important;
          }
        }
      }
      th {
        border: none;
      }
      td {
        border: none;
        label {
          position: inherit !important;
          margin-bottom: 0;
          cursor: pointer;
          border: 2px solid ${({ theme }) => theme.colors.brandSolid};
          width: 1.1em;
          height: 1.1em;
          display: inline-block;
          background: 0 0;
          border-radius: 100%;
          display: flex;
          &.checked {
            background-color: ${({ theme }) => theme.colors.brandColor};
          }
          &:hover {
            background: ${({ theme }) => theme.colors.itemBackgroundHover};
          }
          input {
            opacity: 0;
            width: 0;
          }
        }
      }
    }
  }

  .${({ theme }) => theme.baseName}_container {
    width: 100%;
    h4 {
      margin-bottom: 3rem;
      font-weight: 300;
      font-size: 1.5rem;
    }
    @media (max-width: 564px) {
      h4 {
        font-size: 1.25rem;
        text-align: center;
      }
      .${({ theme }) => theme.baseName}_qstn_title {
        text-align: center;
        padding: 10px 0px;
      }
    }

    .btn-group {
      width: 100%;
      padding-top: 0.5rem;
      label {
        width: 100%;
        margin-bottom: 5px;
        transition: all 0.15s ease-in;
        &.btn {
          background: ${({ theme }) => theme.colors.itemBackground};
          border-color: ${({ theme }) => theme.colors.extraLight};
          color: #212529;
          padding: 0.75rem;
          &:hover {
            background: ${({ theme }) => theme.colors.brandColor};
            color: white;
          }
          &.active {
            background: ${({ theme }) => theme.colors.brandSolid};
            color: white;
          }
        }

        .${({ theme }) => theme.baseName}_q_rating_item_text {
          font-weight: 700;
          cursor: pointer;
          transition: background 0.2s ease-in-out;
          padding: 10px;
          display: block;
          text-align: center;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      @media (max-width: 564px) {
        display: inline-grid;
        padding-bottom: 3rem;
        padding-top: 2rem;
        label {
          margin-top: 5px;
          border-radius: 4px;
          &.btn {
            position: relative;
            border: none;
            border-radius: 4px;
          }
        }
        .${({ theme }) => theme.baseName}_q_rating_item_text {
          font-weight: 700;
          cursor: pointer;
          transition: background 0.2s ease-in-out;
          padding: 20px;
          display: block;
          text-align: center;
          border-radius: 4px;
        }
        .${({ theme }) => theme.baseName}_q_rating_min_text {
          position: absolute;
          top: -30px;
          width: 100%;
          left: 0;
          font-size: 0.85rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors.disabled};
        }
        .${({ theme }) => theme.baseName}_q_rating_max_text {
          position: absolute;
          bottom: -50px;
          width: 100%;
          left: 0;
          font-size: 0.85rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors.disabled};
        }
      }
    }
  }
  .qstn-likert-label {
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.disabled};
    @media (max-width: 564px) {
      justify-content: center;
      &.top {
        margin-bottom: -2rem;
      }
      &.bottom {
        margin-top: -3rem;
      }
    }
  }
`;

export default BaseSurveyWrapper;
