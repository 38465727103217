const PREFIX = 'sv';

const cssNames = {
  root: `${PREFIX}_main ${PREFIX}_bootstrap_css`,
  header: 'panel-heading card-header',
  body: 'panel-body card-block mt-4',
  footer: 'panel-footer card-footer',
  navigationButton: '',
  completedPage: '',
  navigation: {
    complete: `btn ${PREFIX}_complete_btn`,
    prev: `btn ${PREFIX}_prev_btn`,
    next: `btn ${PREFIX}_next_btn`,
    start: `btn ${PREFIX}_start_btn`
  },
  progress: `progress center-block mx-auto mb-4`,
  progressBar: `progress-bar block progress-bar-striped bg-success`,
  page: {
    root: `${PREFIX}_page`,
    title: '',
    description: ''
  },
  pageTitle: '',
  pageDescription: 'small',
  row: `${PREFIX}_row`,
  question: {
    mainRoot: `${PREFIX}_qstn`,
    title: `${PREFIX}_qstn_title`,
    description: 'small',
    comment: 'form-control',
    required: '',
    titleRequired: '',
    hasError: 'has-error',
    indent: 20
  },
  panel: {
    title: `${PREFIX}_p_title`,
    description: `small ${PREFIX}_p_description`,
    container: `${PREFIX}_p_container`
  },
  error: {
    root: 'alert alert-danger',
    icon: 'glyphicon glyphicon-exclamation-sign',
    item: ''
  },
  boolean: {
    root: `${PREFIX}_qbln form-inline checkbox`,
    item: '',
    label: '',
    materialDecorator: 'checkbox-material'
  },
  checkbox: {
    root: `${PREFIX}_qcbc ${PREFIX}_qcbx form-inline`,
    item: 'checkbox',
    itemControl: '',
    controlLabel: '',
    materialDecorator: 'checkbox-material',
    other: `${PREFIX}_q_checkbox_other form-control`
  },
  comment: 'form-control',
  dropdown: {
    root: '',
    control: 'form-control',
    other: `${PREFIX}_q_dd_other form-control`
  },
  matrix: {
    root: `table ${PREFIX}_q_matrix`,
    label: `${PREFIX}_q_m_label`,
    cellText: `${PREFIX}_q_m_cell_text`,
    cellTextSelected: `${PREFIX}_q_m_cell_selected bg-primary`,
    cellLabel: `${PREFIX}_q_m_cell_label`
  },
  matrixdropdown: {
    root: 'table'
  },
  matrixdynamic: {
    root: 'table',
    button: 'button',
    buttonAdd: '',
    buttonRemove: ''
  },
  paneldynamic: {
    root: '',
    button: 'button',
    buttonPrev: '',
    buttonNext: '',
    buttonAdd: '',
    buttonRemove: ''
  },
  multipletext: {
    root: 'table',
    itemTitle: '',
    itemValue: `${PREFIX}_q_mt_item_value form-control`
  },
  radiogroup: {
    root: `${PREFIX}_qcbc form-inline`,
    item: 'radio',
    label: '',
    itemControl: '',
    controlLabel: '',
    materialDecorator: 'circle',
    other: `${PREFIX}_q_radiogroup_other form-control`,
    clearButton: `${PREFIX}_q_radiogroup_clear button`
  },
  imagepicker: {
    root: `${PREFIX}_imgsel`,
    item: `${PREFIX}_q_imgsel`,
    label: `${PREFIX}_q_imgsel_label`,
    itemControl: `${PREFIX}_q_imgsel_control_item`,
    image: `${PREFIX}_q_imgsel_image`,
    itemText: `${PREFIX}_q_imgsel_text`,
    clearButton: `${PREFIX}_q_radiogroup_clear`
  },
  rating: {
    root: 'btn-group',
    item: 'btn btn-primary',
    selected: 'active',
    minText: `${PREFIX}_q_rating_min_text`,
    itemText: `${PREFIX}_q_rating_item_text`,
    maxText: `${PREFIX}_q_rating_max_text`
  },
  text: 'form-control',
  expression: 'form-control',
  file: {
    root: `${PREFIX}_q_file`,
    placeholderInput: `${PREFIX}_q_file_placeholder`,
    preview: `${PREFIX}_q_file_preview`,
    removeButton: `${PREFIX}_q_file_remove_button`,
    fileInput: `${PREFIX}_q_file_input`,
    removeFile: `${PREFIX}_q_file_remove`
  },
  saveData: {
    root: '',
    saving: 'alert alert-info',
    error: 'alert alert-danger',
    success: 'alert alert-success',
    saveAgainButton: ''
  },
  window: {
    root: 'modal-content',
    body: 'modal-body',
    header: {
      root: 'modal-header panel-title',
      title: 'pull-left',
      button: 'glyphicon pull-right',
      buttonExpanded: 'glyphicon pull-right glyphicon-chevron-up',
      buttonCollapsed: 'glyphicon pull-right glyphicon-chevron-down'
    }
  }
};

export default cssNames;
